<template>
  <div class="container min-height flex-column justify-content-spaceBetween align-items-center">
    <div class="width-100 flex-column justify-content-start align-items-center">
      <top :active="4"></top>
      <div class="container-box">
        <div class="width-100 top position-relative flex-row justify-content-spaceBetween align-items-center">
          <div class="left width-50">
            <el-image class="image fadeIn" :src="imagePrefix + newsList[active].image" fit="cover"></el-image>
            <div class="width-100 position-relative carousel">
              <el-carousel :interval="5000" arrow="always" direction="vertical" autoplay indicator-position="none" ref="nop" @change="change">
                <el-carousel-item class="news-title pointer" v-for="(item, index) in newsList" :key="index">
                  <div @click="$router.push({ path: '/informationDetail', query: { id: item.id } })" class="cut-text2">
                    {{ item.title }}
                  </div>
                </el-carousel-item>
              </el-carousel>
              <div class="btn-box">
                <div class="btn" @click="prev()"><i class="el-icon-arrow-up"></i></div>
                <div class="btn" @click="next()"><i class="el-icon-arrow-down"></i></div>
              </div>
            </div>
          </div>
          <div class="right width-50 flex-column justify-content-start align-items-center">
            <div class="width-100 margin-b">
              <p class="title">政策<span class="theme-blue">要文</span></p>
              <!-- <p class="fs-big darkgrey opacity-8">换一批<i class="el-icon-refresh margin-l fs-big"></i></p>-->
            </div>
            <p class="divider-h margin-bs"></p>
            <p
              @click="$router.push({ path: '/informationDetail', query: { id: item.id } })"
              class="fs-big black width-100 cut-text margin-t line-height2 pointer"
              v-for="(item, index) in newsList.slice(0, 7)"
              :key="index"
            >
              {{ item.title }}
            </p>
          </div>
        </div>
        <div
          class="width-100 bottom-box flex-row justify-content-start align-items-star pointer"
          v-for="(item, index) in newsList"
          :key="index"
          @click="$router.push({ path: '/informationDetail', query: { id: item.id } })"
        >
          <div class="width-10 date">
            <p class="day">{{ item.pubdate.substring(8, 10) }}</p>
            <p class="month">{{ item.pubdate.substring(0, 7) }}</p>
          </div>
          <div class="width-20">
            <el-image class="picture" :src="imagePrefix + item.image" fit="cover"></el-image>
          </div>
          <div class="width-70 title">
            <p class="width-100 fs-big fw-mid black margin-b cut-text">{{ item.title }}</p>
            <p class="width-100 fs-mid darkgrey line-height2 margin-bs cut-text2">{{ item.blurb }}</p>
            <p class="width-100 theme-blue pointer line-height2">查看详细</p>
          </div>
        </div>
        <div v-if="moreFlag" class="textAlign-c">
          <el-button @click="nextPage()" plain class="more-button">查看更多</el-button>
        </div>
        <div v-else class="textAlign-c margin-t darkgrey">已经到底了</div>
      </div>
    </div>
    <bottom :bgColor="'#F3F9FF'"></bottom>
  </div>
</template>
<script>
import top from '../components/top';
import bottom from '../components/footer';
import { articleList } from '@/api/TranArticle';

export default {
  name: 'information',
  components: {
    top,
    bottom
  },
  data() {
    return {
      imagePrefix: process.env.VUE_APP_IMAGE_PREFIX,
      active: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      moreFlag: true,
      newsList: []
    };
  },
  created() {
    this.newsList = [];
    this.getArticleListData();
  },
  methods: {
    // 获取资讯列表数据
    getArticleListData() {
      articleList(this.queryParams).then((res) => {
        this.newsList.push(...res.rows);
        if (res.rows.length < this.queryParams.pageSize) {
          this.moreFlag = false;
        }
      });
    },
    // 查看更多
    nextPage() {
      this.queryParams.pageNum++;
      this.getArticleListData();
    },
    prev() {
      this.$refs.nop.prev();
    },
    next() {
      this.$refs.nop.next();
    },

    change(event) {
      this.active = event;
    }
  }
};
</script>

<style lang="less" scoped>
.container-box {
  padding: 40px 0;

  .top {
    margin-bottom: 20px;

    .left {
      .image {
        width: 100%;
        height: 340px;
        vertical-align: center;
      }

      .carousel {
        top: -5px;
        height: 100px;
        background: #3f96ff;

        .el-carousel {
          height: 100px;
        }

        .btn-box {
          right: 0;
          bottom: 5px;
          position: absolute;
          z-index: 3;

          .btn {
            width: 32px;
            height: 32px;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 50%;
            text-align: center;
            line-height: 32px;
            color: white;
            margin: 10px;
            cursor: pointer;
          }
        }

        .news-title {
          padding: 20px 60px 20px 20px;
          width: 100%;

          div {
            font-size: 20px;
            color: white;
            line-height: 30px;
          }
        }
      }
    }
  }

  .right {
    padding-left: 3%;
    height: 450px;

    .title,
    span {
      font-size: 36px;
      font-weight: bolder;
    }
  }

  .bottom-box {
    border-bottom: 1px solid #eeeeee;
    padding: 30px 0;

    .date {
      color: #b1b1b1;
      text-align: center;

      .day {
        font-size: 50px;
      }

      .month {
        font-size: 24px;
      }
    }

    .picture {
      width: 90%;
      height: 150px;
    }
  }
}
</style>
